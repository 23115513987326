import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as storeReducer } from 'src/slices/store';
import { reducer as tunnelHistory } from 'src/slices/tunnelHistory';
import { reducer as tunnelReducer } from 'src/slices/tunnel';
import { reducer as clientReducer } from 'src/slices/client';
import { reducer as clientIntegrationReducer } from 'src/slices/clientIntegration';
import { reducer as stationReducer } from 'src/slices/station';
import { reducer as stationGroupReducer } from 'src/slices/stationGroup';
import { reducer as locationReducer } from 'src/slices/locations';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as superAdminReducer } from 'src/slices/superAdmin';
import { reducer as productReducer } from 'src/slices/product';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as transactionReducer } from 'src/slices/transaction';
import { reducer as transactionHistory } from 'src/slices/transactionHistory';
import { reducer as globalProfileReducer } from 'src/slices/globalProfiles';
import { reducer as operatingScheduleReducer } from 'src/slices/operatingSchedule';
import { reducer as serviceWorkerReducer } from 'src/slices/serviceWorker';
import { reducer as alertsAndNotificationsReducer } from 'src/slices/alertsAndNotifications';
import { reducer as accessControlReducer } from 'src/slices/accessControl';
import { reducer as productImportsReducer } from 'src/slices/productImports';
import { reducer as productImportDetailsReducer } from 'src/slices/productImportDetails';
import { reducer as stationDashboardReducer } from 'src/slices/stationDashboard';
import { reducer as unknownItemsReducer } from 'src/slices/unknownItems';
import { reducer as stationHealthReducer } from 'src/slices/stationHealth';
const rootReducer = combineReducers({
  store: storeReducer,
  tunnel: tunnelReducer,
  tunnelHistory: tunnelHistory,
  client: clientReducer,
  clientIntegration:clientIntegrationReducer,
  station: stationReducer,
  alertsAndNotifications: alertsAndNotificationsReducer,
  stationGroup: stationGroupReducer,
  location:locationReducer,
  user: userReducer,
  superAdmin: superAdminReducer,
  product: productReducer,
  form: formReducer,
  notifications: notificationReducer,
  transaction: transactionReducer,
  transactionHistory: transactionHistory,
  globalProfile: globalProfileReducer,
  operatingSchedule: operatingScheduleReducer,
  serviceWorker: serviceWorkerReducer,
  accessControl: accessControlReducer,
  productImports: productImportsReducer,
  stationDashboard: stationDashboardReducer,
  unknownItems: unknownItemsReducer,
  stationHealth: stationHealthReducer,
  productImportDetails: productImportDetailsReducer
});

export default rootReducer;
