/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import { MyLocation } from '@material-ui/icons';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import HelpIcon from '@material-ui/icons/Help';
import PropTypes from 'prop-types';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import {
  AlertCircle as AlertIcon, AlignJustify as AlignJustifyIcon, Archive as ArchiveIcon,
  Box as BoxIcon, Calendar as CalendarIcon, List as ListIcon, PieChart as PieChartIcon, Settings as SettingsIcon, ShoppingBag as ShoppingBagIcon, Square as SquareIcon, User as UserIcon, Users as UsersIcon,CreditCard as TunnelIcon,
} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import Truncate from 'src/components/Truncate';
import useAcl, { Actions } from 'src/hooks/useAcl';
import { Resources } from 'src/types/resources';
import NavItem from './NavItem';
import useCheckRole from 'src/hooks/useCheckRole';
import { UserRoles } from 'src/types/user';
import useAuth from 'src/hooks/useAuth';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  clientName?: string;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

const managementItems = [];
const dashboardItems = [];

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;
  if (item.items) {
    const open = matchPath({
      path: item.href,
      caseSensitive: false,
      end: false
    }, pathname);
    
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile, clientName }) => {
  const classes = useStyles();
  const location = useLocation();
  const canReadClients = useAcl({ resource: Resources.clients, action: Actions.read });
  const canReadClientIntegrations = useAcl({ resource: Resources.clientIntegrations, action: Actions.read });
  const canReadGlobalProfiles = useAcl({ resource: Resources.globalProfiles, action: Actions.read });
  const canReadUsers = useAcl({ resource: Resources.users, action: Actions.read });
  const canReadProducts = useAcl({ resource: Resources.products, action: Actions.read });
  const canReadStores = useAcl({ resource: Resources.stores, action: Actions.read });
  const canReadStationGroups = useAcl({ resource: Resources.stationGroups, action: Actions.read });
  const canReadStations = useAcl({ resource: Resources.stations, action: Actions.read });
  const canReadLocations = useAcl({ resource: Resources.locations, action: Actions.read });
  const canReadUnknownItems = useAcl({ resource: Resources.products, action: Actions.read });
  const canReadDashboard = useAcl({ resource: Resources.dashboard, action: Actions.read });
  const canReadTransactionHistory = useAcl({ resource: Resources.transactionHistory, action: Actions.read });
  const canReadTunnelHistory = useAcl({ resource: Resources.tunnelHistory, action: Actions.read});
  const canReadAlertsAndNotifications = useAcl({ resource: Resources.alertsAndNotifications, action: Actions.read });
  const canReadOperatingSchedule = useAcl({ resource: Resources.operatingSchedules, action: Actions.read });
  const canReadTunnels = useAcl({ resource: Resources.tunnels, action: Actions.read });
  const [managementMenus, setManagementMenus] = useState(managementItems);
  const [dashboardMenus, setDashboardMenus] = useState(dashboardItems);
  const sections = [];
  const mgmMenus = [];
  const dBoardMenus = [];

  const { user } = useAuth();
  const istunnelManager = useCheckRole(UserRoles.tunnelManager, user);

  sections.push({
    subheader: 'Reports',
    items: dashboardMenus,
  });

  if (managementMenus.length) {
    sections.push({
      subheader: 'Management',
      items: managementMenus,
    });
  }

  useEffect(() => {
    if (canReadDashboard) {
      dBoardMenus.push({
        title: 'Dashboard (Beta)',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      });
    }
    if (canReadStations) {
      dBoardMenus.push({
        title: 'Station Dashboard',
        icon: DashboardIcon,
        href: '/app/reports/station-dashboard'
      });
    }
    if(!istunnelManager)
    {
      dBoardMenus.push({
        title: 'Clerk Dashboard',
        icon: DesktopWindowsIcon,
        href: '/app/reports/clerk-dashboard'
      });
    }
 
    if (canReadUnknownItems) {
      dBoardMenus.push({
        title: 'Unknown Items',
        icon: HelpIcon,
        href: '/app/reports/unknown-items'
      });
    }
    if (canReadTransactionHistory) {
      dBoardMenus.push({
        title: 'Transaction History',
        icon: AlignJustifyIcon,
        href: '/app/reports/transaction'
      });
    }
    if (canReadTunnelHistory) {
      dBoardMenus.push({
        title: 'Tunnel History',
        icon: AlignJustifyIcon,
        href: '/app/reports/tunnel'
      });
    }
    if (canReadProducts) {
      dBoardMenus.push({
        title: 'Import History',
        icon: ListIcon,
        href: '/app/management/import-product-history',
      });
    }
    if (canReadClients) {
      mgmMenus.push({
        title: 'Clients',
        icon: UserIcon,
        href: '/app/management/clients',
      });
    }
     if (canReadClientIntegrations)
    {
      mgmMenus.push({
        title: 'Integrations',
        icon: DeviceHubIcon,
        href: '/app/management/client-integration',
      });
     }
    if (canReadUsers) {
      mgmMenus.push({
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/users',
      });
    }
    if (canReadProducts) {
      mgmMenus.push({
        title: 'Products',
        icon: BoxIcon,
        href: '/app/management/products',
      });
    }
    if (canReadStores) {
      mgmMenus.push({
        title: 'Stores',
        icon: ShoppingBagIcon,
        href: '/app/management/stores',
      });
    }
    if (canReadStationGroups) {
      mgmMenus.push({
        title: 'Station Groups',
        icon: SquareIcon,
        href: '/app/management/station-groups',
      });
    }
    if (canReadStations) {
      mgmMenus.push({
        title: 'Stations',
        icon: ArchiveIcon,
        href: '/app/management/stations',
      });
    }
    if (canReadLocations) {
      mgmMenus.push({
        title: 'Locations',
        icon: MyLocation,
        href: '/app/management/locations',
      });
    }
    if (canReadTunnels) {
      mgmMenus.push({
        title: 'Tunnels',
        icon: TunnelIcon,
        href: '/app/management/tunnels',
      });
    }
    if (canReadGlobalProfiles) {
      mgmMenus.push({
        title: 'Global Profiles',
        icon: SettingsIcon,
        href: '/app/management/global-profiles',
      });
    }
    if (canReadAlertsAndNotifications) {
      mgmMenus.push({
        title: 'Alerts & Notifications',
        icon: AlertIcon,
        href: '/app/reports/alerts-and-notifications'
      });
    }
    if (canReadOperatingSchedule) {
      mgmMenus.push({
        title: 'Operating Schedule',
        icon: CalendarIcon,
        href: '/app/reports/operating-schedule'
      });
    }
    setManagementMenus([...mgmMenus, ...managementMenus]);
    setDashboardMenus([...dBoardMenus, ...dashboardMenus]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canReadClients,
    canReadClientIntegrations,
    canReadGlobalProfiles,
    canReadUsers,
    canReadProducts,
    canReadStores,
    canReadStationGroups,
    canReadStations,
    canReadLocations,
    canReadDashboard,
    canReadAlertsAndNotifications,
    canReadTransactionHistory,
    canReadTunnelHistory,
    canReadOperatingSchedule,
    canReadTunnels
  ]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
          >
            <Typography variant="h5">
              <Truncate value={clientName ?? ''} />
            </Typography>
          </Box>

        </Hidden>

        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
